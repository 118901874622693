import React from 'react';
import { graphql } from 'gatsby';
import { Seo } from '../../components/Seo';
import PostPageView from '../../views/postpage-view';

const PostPage = ({data} ) => {
    return <PostPageView postData={data} />;
};
export const Head = ({ location, data }) => {
  const { title, ingress, layout, lang, date, coverImage: {childImageSharp: {original: {src, width, height}}}} = data.markdownRemark.frontmatter;
  return <Seo title={title} description={ingress} type={layout} date={date} lang={lang}
            image={src} imgWidth={width} imgHeight={height} pathname={location.pathname}  
        />
};

export default PostPage;

export const query = graphql`
query($id: String!) {
  markdownRemark(frontmatter: {layout: {ne: "dontremove"}}, id: {eq: $id }) {
    htmlAst
    frontmatter {
      layout
      title
      ingress
      date(formatString: "DD MMMM, YYYY")
      lang
      coverImage {
        childImageSharp {
          original {
            src
            width
            height
          }
          fixed {
            src
          }
          gatsbyImageData(
            aspectRatio: 1.5
            placeholder: BLURRED
            width: 1200
          )
        }
      }
    }
  }
}`;