import React from "react";
import {
    NewsContainer, NewsWrapper, TopLine, Paragraph, Title, SubTitle,
    TextWrapper, ImgWrap, Img, GoBack, ButtonWrapper, Button, List,
} from "../styles/StyledPost";
import rehypeReact from 'rehype-react';

const PostPageView = ({ postData }) => {
    const {
        htmlAst,
        frontmatter: { date, title, ingress, // layout,
            coverImage: { childImageSharp: { gatsbyImageData: {
                images: { fallback: { src, sizes }, sources: [{ srcSet }] },
                /* original: { src: originalSrc} */ }}
            }
    }} = postData.markdownRemark;

    const renderAst = new rehypeReact({
        createElement: React.createElement,
        Fragment: React.Fragment,
        components: {
            h2: SubTitle,
            p: Paragraph, props: {lightText: true},
            ol: List,
        },
    }).Compiler


    return (
        <NewsContainer>
        <NewsWrapper>
            <TextWrapper>
                <ImgWrap>
                    <Img srcset={srcSet} sizes={sizes} src={src} alt={title} title={title} />
                </ImgWrap>
                <Title lightText={false}>{title}</Title>
                <Paragraph key='ingress' isTitle={false} lightText={false}> {ingress} </Paragraph>
                {
                    renderAst(htmlAst)
                }
                <ButtonWrapper buttonVisible={true}>
                    <Button big={false} dark={false} fontBig={false} to='../'>
                    <GoBack />Back
                    </Button>
                    <TopLine> {date} </TopLine>
                </ButtonWrapper>
            </TextWrapper>
        </NewsWrapper>
    </NewsContainer>
    );
}
export default PostPageView