import { useStaticQuery, graphql } from 'gatsby';

const useSiteMetadata = () => {
  const data = useStaticQuery(graphql`query {
    site {
      siteMetadata {
        description
        siteUrl
        title
        image
      }
    }
  }`);
  return data.site.siteMetadata
};
export default useSiteMetadata
