import React from 'react'
import useSiteMetadata from '../hooks/use-site-metadata'

export const Seo = ({ title, description, lang, type, pathname, image, imgWidth, imgHeight, date, children }) => {
    const {
        title: defaultTitle,
        description: defaultDescription,
        image: defaultImage,
        siteUrl,
        twitterUsername,
    } = useSiteMetadata()

    const seo = {
        title: title || defaultTitle,
        description: description || defaultDescription,
        locale: lang || 'sv_SE',
        date: date || '',
        image: `${siteUrl}${image}` || defaultImage,
        img_alt: title || '',
        img_width: imgWidth || '',
        img_height: imgHeight || '', 
        url: `${siteUrl}${pathname || ``}`,
        twitterUsername,
    }

    return (
          <>
            {/* General */}
            <title>{seo.title}</title>
            <meta name="title" content={seo.title} />
            <meta name="description" content={seo.description} />
            <meta name="image" content={seo.image} />

            {/* Twitter */}
            <meta name="twitter:card" property="twitter:card" content="summary_large_image" />
            <meta name="twitter:url" property="twitter:url" content={seo.url} />
            <meta name="twitter:title" property="twitter:title" content={seo.title} />
            <meta name="twitter:description" property="twitter:description" content={seo.description} />
            <meta name="twitter:imgage" property="twitter:image" content={seo.image} />

            {/* Open Graph / Facebook */}
            <meta name="og:type" property="og:type" content={type} />
            <meta name="og:url" property="og:url" content={seo.url} />
            <meta name="og:title" property="og:title" content={seo.title} />
            <meta name="og:description" property="og:description" content={seo.description} />
            <meta name="og:locale" property="og:locale" content={seo.locale} />
            <meta name="og:locale:alternate" property="og:locale:alternate" content="en_US" />
            <meta name="og:locale:alternate" property="og:locale:alternate" content="sv_SE" />
            <meta name="og:imgage" property="og:image" content={seo.image} />
            <meta name="og:imgage:url" property="og:image:url" content={seo.image} />
            <meta name="og:imgage:alt" property="og:image:alt" content={seo.img_alt} />
            <meta name="og-imgage:width" property="og:image:width" content={seo.img_width} />
            <meta name="og:imgage:height" property="og:image:height" content={seo.img_height} />
            <meta name="og:publish_date" property="og:publish_date" content={seo.date} />
            <meta name="og:article:author" property="og:article:author" content='Anzr Co' />
            <meta name="og:author" property="og:author" content='Anzr Co' />
            {children}
          </>
    )
}
