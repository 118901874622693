import styled from 'styled-components';
import { MdArrowBackIosNew } from 'react-icons/md';
import { Link } from 'gatsby';

export const NewsContainer = styled.div`
  color: #010606;
  background: ${({lightText}) => (lightText ? 'var(--anzr-blue)' : '#f9f9f9')};
  padding: 200px 100px 0 0;
  min-width: 360px;

  @media screen and  (max-width: 768px) {
    padding: 100px 0;
  }
`
export const NewsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 1;
  max-width: 1100px;
  margin-right: auto;
  margin-left: auto;
  padding: 0 24px;
  justify-content: center;
`
export const TextWrapper = styled.div`
  max-width: 800px;
  padding-top: 0;
  padding-bottom: 5em;
  align-self: center;
`
export const TopLine = styled.div`
  color: var(--anzr-green);
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  text-align: right;

`

export const Title = styled.h1`
  margin-top: 1.5em;
  margin-bottom: 0.5em;
  font-size: 48px;
  line-height: 1.1;
  font-weight: 600;
  color: ${({lightText}) => (lightText ? '#fff' : '#010606')};

  @media screen and (max-width: 480px) {
    font-size: 32px;
  }
`
export const SubTitle = styled.h2`
  margin-top: 35px;
  margin-bottom: 4px;
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
`

export const Paragraph  = styled.p`
  margin-bottom: 16px;
  font-size: 18px;
  line-height: 28px;
  color: ${({lightText}) => (lightText ? '#fff' : '#010606')};
  text-align: justify;
  word-wrap: break-word;
`

export const List = styled.ol`
  margin-left: 35px;
  margin-bottom: 35px;
  font-size: 18px;
  line-height: 28px;
  color: #010606;
`
export const ImgWrap = styled.div`
  max-width: 800px;
  margin-bottom: 2rem;
`
export const Img = styled.img`
  width: 100%;
`
export const TextBlock = styled.div`
  position: absolute;
  bottom: 20px;
  background-color: #303030;
  color: #f9f9f9;
  padding-left: 20px;
  padding-right: 20px;
  
  & > h1,p {
    font-size: 1.5rem;
    @media screen and (max-width: 680px) { font-size: 1rem; }
  }
`
export const ButtonWrapper = styled.div`
  margin-top: 1.8rem;
  padding-right: 2rem;
  flex-direction: row;
  align-items: flex-start;
  display: ${({buttonVisible}) => (buttonVisible ? 'flex' : 'none')};
  justify-content: space-between;
  
`
export const Button = styled(Link)`
  border-radius: 50px;
  background: ${({primary}) => (primary ?  'var(--anzr-green)' : 'var(--anzr-blue)')};
  white-space: nowrap;
  padding: ${({big}) =>  (big ? '14px  48px' : '12px 30px')};
  color: ${({dark}) =>  (dark ? '#010606' : '#f6f6f6')};
  font-size:${({fontBig}) =>  (fontBig ? '1.5rem' : '0.875rem')};
  text-decoration: none;
  outline: none;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  transition: all 0.2s ease-in-out;

  &:hover {
    transition: all 0.2s ease-in-out;
    color: var(--anzr-light-green);

  }
`
export const GoBack = styled(MdArrowBackIosNew)`
  font-size: 1.2em;
`